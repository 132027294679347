import { Feature, MapBrowserEvent, Overlay } from "ol";
import LineString from "ol/geom/LineString";
import Polygon from "ol/geom/Polygon";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { getArea, getLength } from 'ol/sphere';
import { Draw } from "ol/interaction";
import { DrawEvent } from "ol/interaction/Draw";
import { unByKey } from 'ol/Observable';
import Geometry from "ol/geom/Geometry";
import BaseEvent from "ol/events/Event";
import { Map as olMap } from 'ol';
import { MapMode } from './MapFunctions';

const source = new VectorSource();
let map: olMap = null;

export function InitMeasureTool(mapView: olMap) {
	map = mapView;
	map.addLayer(vector);
	map.getViewport().addEventListener('mouseout', function () {
		if (helpTooltipElement != null) helpTooltipElement.classList.add('hidden');
	});
}


const vector = new VectorLayer({
	source: source,
	style: new Style({
		zIndex: 10000,
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.2)',
		}),
		stroke: new Stroke({
			color: '#ffcc33',
			width: 2,
		}),
		image: new CircleStyle({
			radius: 7,
			fill: new Fill({
				color: '#ffcc33',
			}),
		}),
	}),
});



let sketch: Feature<any>;

let helpTooltipElement: HTMLElement;

let helpTooltip: Overlay;

let measureTooltipElement: HTMLElement;

let measureTooltip: Overlay;

const continuePolygonMsg = 'Click to continue drawing the polygon';
const continueLineMsg = 'Click to continue drawing the line';

export function UpdateMeasureTool(evt: MapBrowserEvent<any>) {
	if (evt.dragging) {
		return;
	}
	if (helpTooltipElement == null) return;
	/** @type {string} */
	let helpMsg = 'Click to start drawing';

	if (sketch) {
		const geom = sketch.getGeometry();
		if (geom instanceof Polygon) {
			helpMsg = continuePolygonMsg;
		} else if (geom instanceof LineString) {
			helpMsg = continueLineMsg;
		}
	}

	helpTooltipElement.innerHTML = helpMsg;
	helpTooltip.setPosition(evt.coordinate);

	helpTooltipElement.classList.remove('hidden');
}

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const formatLength = function (line: LineString) {
	const length = getLength(line);
	const lengthFt = length * 3.2808399;
	const lengthMile = length * 0.000621371192;

	let output;
	if (length > 1000) {
		output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km<br/>';
	} else {
		output = Math.round(length * 100) / 100 + ' ' + 'm<br/>';
	}

	if (lengthFt > 5280) {
		output += Math.round(lengthMile * 100) / 100 + ' ' + 'mi';
	}
	else {
		output += Math.round(lengthFt * 100) / 100 + ' ' + 'ft';

	}

	return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = function (polygon: Polygon) {
	const area = getArea(polygon);
	const areaSqKm = area / 1000 / 1000;
	const areaSqFt = area * 10.7639;
	const areaSqMi = areaSqKm * 0.386102;

	let output;
	if (area > 10000) {
		output = Math.round(areaSqKm * 1000) / 1000 + ' ' + 'km<sup>2</sup><br/>';
	} else {
		output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup><br/>';
	}

	if (areaSqFt > 100000) {
		output = Math.round(areaSqMi * 1000) / 1000 + ' ' + 'mi<sup>2</sup><br/>';
	}
	else {
		output = Math.round(areaSqFt * 1000) / 1000 + ' ' + 'ft<sup>2</sup><br/>';
	}

	return output;
};


let drawInteraction: Draw;



function addInteraction(mode: MapMode) {
	if (mode != MapMode.MeasureArea && mode != MapMode.MeasureLength) return;
	const type = (mode == MapMode.MeasureArea) ? 'Polygon' : 'LineString';

	drawInteraction = new Draw({
		source: source,
		type: type,
		style: new Style({
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)',
			}),
			stroke: new Stroke({
				color: 'rgba(0, 0, 0, 0.5)',
				lineDash: [10, 10],
				width: 2,
			}),
			image: new CircleStyle({
				radius: 5,
				stroke: new Stroke({
					color: 'rgba(0, 0, 0, 0.7)',
				}),
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)',
				}),
			}),
		}),
	});
	map.addInteraction(drawInteraction);

	createMeasureTooltip();
	createHelpTooltip();

	let listener: any;
	drawInteraction.on('drawstart', function (evt: any) {
		// set sketch
		sketch = evt.feature;

		let tooltipCoord = evt.coordinate;

		let geom = <Geometry>sketch.getGeometry();

		listener = geom.on('change', function (evt1: BaseEvent) {
			const geom = evt1.target;
			let output;
			if (geom instanceof Polygon) {
				output = formatArea(geom);
				tooltipCoord = geom.getInteriorPoint().getCoordinates();
			} else if (geom instanceof LineString) {
				output = formatLength(geom);
				tooltipCoord = geom.getLastCoordinate();
			}
			measureTooltipElement.innerHTML = output;
			measureTooltip.setPosition(tooltipCoord);
		});
	});

	drawInteraction.on('drawend', function () {
		measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
		measureTooltip.setOffset([0, -7]);
		// unset sketch
		sketch = null;
		// unset tooltip so that a new one can be created
		measureTooltipElement = null;
		createMeasureTooltip();
		unByKey(listener);
	});
}

/**
 * Creates a new help tooltip
 */
function createHelpTooltip() {
	if (helpTooltipElement) {
		if (helpTooltipElement.parentNode) {
			helpTooltipElement.parentNode.removeChild(helpTooltipElement);
		}
	}
	helpTooltipElement = document.createElement('div');
	helpTooltipElement.className = 'ol-tooltip hidden';
	helpTooltip = new Overlay({
		id: 'helpTooltip',
		element: helpTooltipElement,
		offset: [15, 0],
		positioning: 'center-left',
	});
	map.addOverlay(helpTooltip);
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip() {
	if (measureTooltipElement) {
		if (measureTooltipElement.parentNode) {
			measureTooltipElement.parentNode.removeChild(measureTooltipElement);
		}
	}
	measureTooltipElement = document.createElement('div');
	measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
	measureTooltip = new Overlay({
		id: 'measureTooltip',
		element: measureTooltipElement,
		offset: [0, -15],
		positioning: 'bottom-center',
		stopEvent: false,
		insertFirst: false,
	});
	map.addOverlay(measureTooltip);
}

function ClearMap() {
	map.getOverlays().forEach((overlay, b, c) => {
		if (overlay === undefined) return;
		if (overlay.getId() == "measureTooltip") map.removeOverlay(overlay);
		if (overlay.getId() == "helpTooltip") map.removeOverlay(overlay);
	});

	var tooltips = document.querySelectorAll('.ol-tooltip');
	tooltips.forEach(t => {
		if (t != null && t.parentNode != null) t.parentNode.removeChild(t)
	});


	if (helpTooltipElement) {
		if (helpTooltipElement.parentNode) {
			helpTooltipElement.parentNode.removeChild(helpTooltipElement);
		}
	}

	if (measureTooltipElement) {
		if (measureTooltipElement.parentNode) {
			measureTooltipElement.parentNode.removeChild(measureTooltipElement);
		}
	}

	source.clear();
	map.removeInteraction(drawInteraction);
}

export function SetMeasureMode(mode: MapMode) {
	ClearMap();
	addInteraction(mode);
}

