import { createPopper, Instance, Placement, popper } from "@popperjs/core";

export class PopperPanel {
	Name: string
	popper: Instance
	divElement: HTMLDivElement
	buttonElement: HTMLButtonElement

	constructor(name: string, div: HTMLDivElement, button: HTMLButtonElement, placement: Placement) {
		this.Name = name;
		this.divElement = div;
		this.buttonElement = button;

		this.popper = null;

		if (button == null) return;
		if (div == null) return;

		this.popper = createPopper(button, div, {
			placement: placement,
			modifiers: [
				{ name: 'offset', options: { offset: [0, 10] } },
				{ name: 'preventOverflow', options: { rootBoundary: 'viewport', elementContext: 'reference', altAxis: false } }
			]
		});
	}

	isShown(): boolean {
		if (this.popper == null) return false;
		return this.divElement.hasAttribute('data-show');
	}

	hidePopper() {
		if (this.popper == null) return;
		this.divElement.removeAttribute('data-show');
		this.popper.update();
	}

	showPopper() {
		HideOtherPoppers(this.Name);
		if (this.popper == null) return;

		this.divElement.setAttribute('data-show', '');
		this.popper.update();
	}
	updatePopper() {
		if (this.popper == null) return;
		this.popper.update();
	}


	togglePopper() {
		if (this.isShown()) {
			this.hidePopper();
		}
		else {
			this.showPopper();
		}
	}
}

let poppers: PopperPanel[] = [];

export function AddPopper(name: string, div: HTMLDivElement, button: HTMLButtonElement, placement: Placement): PopperPanel {
	let pp = new PopperPanel(name, div, button, placement);
	poppers.push(pp);
	return pp;
}


function HideOtherPoppers(name: string) {
	poppers.forEach(p => {
		if (p.Name != name) {
			p.hidePopper();
		}
	});
}


function ShowPopper(name: string) {
	poppers.forEach(p => {
		if (p.Name == name) {
			p.showPopper();
		}
	});
}