import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import RenderFeature from 'ol/render/Feature';
import { Style, Fill, Stroke, Text, Icon } from 'ol/style';
import { map } from './MapView';
import { getFeatures, trackPointStyleFunction, vehicleStyleFunction } from '../MapFunctions';



const chkVehicleLayer = document.querySelector<HTMLInputElement>('#chkVehicles');
const chkTrackLayer = document.querySelector<HTMLInputElement>('#chkTrackPoints');

const btnRefresh = document.querySelector<HTMLButtonElement>('#btnRefresh');


let trackPointsTimeRangeMin = 5;

let btnApplyTimeRange = document.querySelector<HTMLButtonElement>('#btnApplyTimeRange');
let numTimeRange = document.querySelector<HTMLInputElement>('#numTimeRange');


btnApplyTimeRange.onclick = function () {
	trackPointsTimeRangeMin = parseInt(numTimeRange.value);
	if (isNaN(trackPointsTimeRangeMin)) {
		trackPointsTimeRangeMin = 5;
		numTimeRange.value = "5"
	}

	if (trackPointsTimeRangeMin > 30) {
		trackPointsTimeRangeMin = 30
		numTimeRange.value = "30"
	}

	updateTrackPointLayer();
}

var vehicleLayer: VectorLayer<VectorSource<Geometry>>;
var trackPointsLayer: VectorLayer<VectorSource<Geometry>>;

function updateVehicleLayer() {
	$.ajax({
		type: 'GET',
		url: '/Map/GetVehiclePoints',
		success: function (resp) {
			let features = getFeatures(resp);
			let featureSource = new VectorSource({
				features: features
			});
			vehicleLayer.setSource(featureSource);
			vehicleLayer.changed();
		}
	});
}


function updateTrackPointLayer() {
	let data = { duration: trackPointsTimeRangeMin }
	$.ajax({
		type: 'GET',
		url: '/Map/GetTrackerPoints',
		data: data,
		success: function (resp) {
			let features = getFeatures(resp);
			let featureSource = new VectorSource({
				features: features
			});
			trackPointsLayer.setSource(featureSource);
			trackPointsLayer.changed();
		}
	});
}

function addLayers() {
	vehicleLayer = new VectorLayer({
		source: null,
		style: vehicleStyleFunction,
		zIndex: 100002
	});

	trackPointsLayer = new VectorLayer({
		source: null,
		style: trackPointStyleFunction,
		zIndex: 100001
	});

	map.addLayer(vehicleLayer);
	map.addLayer(trackPointsLayer);

	vehicleLayer.setVisible(chkVehicleLayer.checked);
	trackPointsLayer.setVisible(chkTrackLayer.checked);
}



export function AddTrackerLayers() {
	addLayers();
	updateVehicleLayer();
	updateTrackPointLayer();
	window.setInterval(updateVehicleLayer, 1000);
	window.setInterval(updateTrackPointLayer, 10000);
}




chkVehicleLayer.onchange = function () {
	vehicleLayer.setVisible(chkVehicleLayer.checked);
}


chkTrackLayer.onchange = function () {
	trackPointsLayer.setVisible(chkTrackLayer.checked);
}



btnRefresh.onclick = function () {
	updateVehicleLayer();
	updateTrackPointLayer();
}



let chkBus = document.querySelector<HTMLInputElement>('#chkBus');
let chkTaxi = document.querySelector<HTMLInputElement>('#chkTaxi');
let chkPrivateCar = document.querySelector<HTMLInputElement>('#chkPrivateCar');


function layersChanged() {
	vehicleLayer.changed();
	trackPointsLayer.changed();
}

chkBus.onchange = layersChanged;
chkTaxi.onchange = layersChanged;
chkPrivateCar.onchange = layersChanged;
