import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';

import RenderFeature from 'ol/render/Feature';
import { Style, Fill, Stroke, Text, Icon, RegularShape } from 'ol/style';
import { Map as olMap } from 'ol';
import { LineString, Point } from 'ol/geom';


let map: olMap = null;

let iconStyles = new Map<string, Icon>()
let trackPointIcons = new Map<string, Icon>()


let chkBus = document.querySelector<HTMLInputElement>('#chkBus');
let chkTaxi = document.querySelector<HTMLInputElement>('#chkTaxi');
let chkPrivateCar = document.querySelector<HTMLInputElement>('#chkPrivateCar');
let chkMotorbike = document.querySelector<HTMLInputElement>('#chkMotorbike');
let chkTruck = document.querySelector<HTMLInputElement>('#chkTruck');

export var speedColorInvalid = '#a4a4a4';
export var speedColorRed = '#f80400';
export var speedColorYellow = '#ffbe00';
export var speedColorGreen = '#007d00';
export var speedColorBlue = '#1e679c';
export var speedRed = 10;
export var speedYellow = 20;
export var speedGreen = 30;


export function InitMapLayers(mapView: olMap) {
	map = mapView;
}


export enum MapMode {
	Identify,
	ZoomIn,
	ZoomOut,
	MeasureLength,
	MeasureArea,
	DrawSite,
	DrawSiteArea,
	InsertVertex,
	DeleteVertex
}

export function getFeatures(geojson: string): Feature[] {
	let features = new GeoJSON().readFeatures(JSON.parse(geojson));
	for (let i = 0; i < features.length; i++) {
		let of = features[i]
		let geom = of.getGeometry() as Geometry;
		geom = geom.transform('EPSG:4326', 'EPSG:3857');
		of.setGeometry(geom);
	}

	return features;
}





export function vehicleStyleFunction(feature: RenderFeature | Feature<any>) {
	let props = feature.getProperties();
	console.log(props);

	let bearing: number = parseFloat(props["_bearing"]);
	let color = props["_color"];
	let speed: number = parseFloat(props["_speed"]);
	let trackerId = props["Tracker ID"];
	let ownerName = props["Name"];
	let timeLabel = props["_time"];
	//let vehicleNo = props["Vehicle No."]
	let vehicleType = props["Vehicle Type"];

	if (vehicleType == "TAXI" && !chkTaxi.checked) return new Style();
	if (vehicleType == "BUS" && !chkBus.checked) return new Style();
	if (vehicleType == "PRIVATECAR" && !chkPrivateCar.checked) return new Style();
	if (vehicleType == "MOTORBIKE" && !chkMotorbike.checked) return new Style();
	if (vehicleType == "TRUCK" && !chkTruck.checked) return new Style();

	let icon: Icon;

	if (iconStyles.has(trackerId)) {
		icon = iconStyles.get(trackerId);
		icon.setRotation(bearing * Math.PI / 180);
	}
	else {
		if (vehicleType == "BUS") {
			icon = new Icon({
				src: "img/bus.svg",
				scale: 0.5,
				color: color,
				rotation: bearing * Math.PI / 180
			});
		}
		else if (vehicleType == "TRUCK") {
			icon = new Icon({
				src: "img/truck.svg",
				scale: 0.3,
				color: color,
				rotation: bearing * Math.PI / 180
			});
		}
		else if (vehicleType == "TAXI") {
			icon = new Icon({
				src: "img/car_topview2.svg",
				scale: 0.3,
				color: color,
				rotation: bearing * Math.PI / 180
			});
		}
		else if (vehicleType == "MOTORBIKE") {
			icon = new Icon({
				src: "img/VehiclePoint.png",
				scale: 0.2,
				color: color,
				rotation: bearing * Math.PI / 180
			});
		}
		else
			icon = new Icon({
				src: "img/car_topview.svg",
				scale: 0.3,
				color: color,
				rotation: bearing * Math.PI / 180
			});
		iconStyles.set(trackerId, icon);
	}

	let iconStyle = new Style({
		image: icon,
	});


	let labelStyle = new Style({
		text: new Text({
			font: '9px sans-serif',
			overflow: true,
			fill: new Fill({
				color: '#000'
			}),
			stroke: new Stroke({
				color: '#fff'
			}),
			offsetY: -18,

			text: ownerName  //+ "TR" + trackerId
		})
	});

	let speedLabelStyle = new Style({
		text: new Text({
			font: '9px sans-serif',
			overflow: true,
			fill: new Fill({
				color: '#000'
			}),
			stroke: new Stroke({
				color: '#fff'
			}),

			offsetY: 22,
			text: speed.toFixed(1) + " kph, " + bearing.toFixed(0) + "°\r\n" + timeLabel
		})
	});

	if (map.getView().getZoom() > 15) {
		return [iconStyle, labelStyle, speedLabelStyle];
	}
	else {
		return iconStyle;
	}

}


export function trackPointSpeedStyleFunction(feature: RenderFeature | Feature<any>) {
	let props = feature.getProperties()

	let bearing: number = parseFloat(props["_bearing"]);
	let color = props["_color"];
	let speed: number = parseFloat(props["_speed"]);


	if (speed < speedRed) {
		color = speedColorRed;
	}
	else if (speed < speedYellow) {
		color = speedColorYellow;
	}
	else if (speed < speedGreen) {
		color = speedColorGreen;
	}
	else {
		color = speedColorBlue;
	}


	let trackerId = props["Tracker ID"]
	let vehicleType = props["Vehicle Type"]

	if (vehicleType == "TAXI" && !chkTaxi.checked) return new Style()
	if (vehicleType == "BUS" && !chkBus.checked) return new Style()
	if (vehicleType == "PRIVATECAR" && !chkPrivateCar.checked) return new Style()


	let icon = new Icon({
		src: "img/arrow.svg",
		scale: 0.15,
		color: color,
		rotation: bearing * Math.PI / 180
	});

	trackPointIcons.set(trackerId, icon);


	let iconStyle = new Style({
		image: icon,
	});

	let speedLabelStyle = new Style({
		text: new Text({
			font: '9px sans-serif',
			overflow: true,
			fill: new Fill({
				color: color
			}),
			offsetY: 16,
			text: speed.toFixed(1) + " kph"
		})
	});

	if (map.getView().getZoom() > 19) {
		return [iconStyle, speedLabelStyle];
	}
	else {
		return iconStyle;
	}

}

export function trackPointStyleFunction(feature: RenderFeature | Feature<any>) {
	let props = feature.getProperties()

	let bearing: number = parseFloat(props["_bearing"]);
	let color = props["_color"];
	let speed: number = parseFloat(props["_speed"]);
	let trackerId = props["Tracker ID"]
	let vehicleType = props["Vehicle Type"]

	if (vehicleType == "TAXI" && !chkTaxi.checked) return new Style()
	if (vehicleType == "BUS" && !chkBus.checked) return new Style()
	if (vehicleType == "PRIVATECAR" && !chkPrivateCar.checked) return new Style()


	let icon: Icon;
	if (trackPointIcons.has(trackerId)) {
		icon = trackPointIcons.get(trackerId);
		icon.setRotation(bearing * Math.PI / 180);
	}
	else {
		icon = new Icon({
			src: "img/arrow.svg",
			scale: 0.15,
			color: color,
			rotation: bearing * Math.PI / 180
		});
		trackPointIcons.set(trackerId, icon);
	}

	let iconStyle = new Style({
		image: icon,
	});

	let speedLabelStyle = new Style({
		text: new Text({
			font: '9px sans-serif',
			overflow: true,
			fill: new Fill({
				color: color
			}),
			offsetY: 16,
			text: speed.toFixed(1) + " kph"
		})
	});

	if (map.getView().getZoom() > 19) {
		return [iconStyle, speedLabelStyle];
	}
	else {
		return iconStyle;
	}

}



export function routeStyleFunction(feature: RenderFeature | Feature<any>) {
	let props = feature.getProperties();

	let routeId = props['ID'];
	let lineColor = '#0F0';
	let zIndex = 100;

	let speed = props["Speed"];

	if (props["Sample Count"] == 0) {
		lineColor = speedColorInvalid;
		zIndex = 0;
	}
	else if (speed < speedRed) {
		lineColor = speedColorRed;
	}
	else if (speed < speedYellow) {
		lineColor = speedColorYellow;
	}
	else if (speed < speedGreen) {
		lineColor = speedColorGreen;
	}
	else {
		lineColor = speedColorBlue;
	}



	let style = new Style({
		stroke: new Stroke({
			width: 3,
			color: lineColor,
			lineCap: 'round'
		}),
		zIndex: zIndex
	});

	const styles = [style];

	let geometry: LineString = feature.getGeometry();

	let mp = geometry.getFlatMidpoint();
	let v1 = geometry.getFirstCoordinate();
	let v2 = geometry.getLastCoordinate();
	let minD = Number.MAX_VALUE;

	geometry.forEachSegment(function (start, end) {
		let d1 = Math.sqrt(Math.pow(mp[0] - start[0], 2) + Math.pow(mp[1] - start[1], 2));
		let d2 = Math.sqrt(Math.pow(mp[0] - end[0], 2) + Math.pow(mp[1] - end[1], 2));
		let d = d1 + d2;
		if (d < minD) {
			minD = d;
			v1 = start;
			v2 = end;
		}
	});

	let zoom = map.getView().getZoom();
	console.log(zoom);
	if ( zoom > 14) {
		const dx = v2[0] - v1[0];
		const dy = v2[1] - v1[1];
		const rotation = Math.atan2(dx, dy);
		// arrows
		styles.push(
			new Style({
				geometry: new Point(mp),
				image: new Icon({
					src: "img/arrow.svg",
					scale: 0.15,
					color: lineColor,
					rotation: rotation + Math.PI
				}),
			})
		);
	}

	return styles;
}
