import 'ol/ol.css';
import * as $ from "jquery";

import { always } from 'ol/events/condition';
import { toLonLat } from 'ol/proj';
import { Map as olMap, Overlay, View } from 'ol';
import DragZoom from 'ol/interaction/DragZoom'
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import Attribution from 'ol/control/Attribution';
import Rotate from 'ol/control/Rotate';
import { defaults } from 'ol/interaction';

import {  SetActiveBasemap, SetupBaseMaps } from '../BaseMaps';
import { InitMeasureTool, SetMeasureMode, UpdateMeasureTool } from '../MapMeasure';
import { GetBgMapState, LoadMapExtents, SaveMapState } from '../MapViewSession';
import { AddPopper } from '../PopperPanel';
import { AddTrackerLayers } from './TrackerLayers';
import { InitMapLayers, MapMode } from '../MapFunctions';



///UI components
const cover = document.querySelector<HTMLDivElement>('#cover');
const mapDiv = document.querySelector<HTMLDivElement>('#map');
const btnSearch = document.querySelector<HTMLButtonElement>('#btnSearch');
const btnLayers = document.querySelector<HTMLButtonElement>('#btnLayers');
const btnTraffic = document.querySelector<HTMLButtonElement>('#btnTraffic');

const layersPanel = document.querySelector<HTMLDivElement>('#layersPanel');
const searchPanel = document.querySelector<HTMLDivElement>('#searchPanel');
const trafficPanel = document.querySelector<HTMLDivElement>('#trafficPanel');

const btnZoomIn = document.querySelector<HTMLButtonElement>('#btnZoomIn');
const btnZoomOut = document.querySelector<HTMLButtonElement>('#btnZoomOut');
const btnIdentify = document.querySelector<HTMLButtonElement>('#btnIdentify');

const btnMeasureLength = document.querySelector<HTMLButtonElement>('#btnMeasureLength');
const btnMeasureArea = document.querySelector<HTMLButtonElement>('#btnMeasureArea');

const layerTreeContainer = document.querySelector<HTMLDivElement>('#layerTree');

const mapPopup = document.querySelector<HTMLDivElement>('#map-popup');
const mapPopupContent = document.querySelector<HTMLDivElement>('#map-popup-content');
const mapPopupCloser = document.querySelector<HTMLDivElement>('#map-popup-closer');

//Search
const txtSearch = document.querySelector<HTMLInputElement>('#txtSearch');
const btnApplySearch = document.querySelector<HTMLInputElement>('#btnApplySearch');

//Map Interactions
const zoomInInteraction: DragZoom = new DragZoom({ condition: always, out: false });
const zoomOutInteraction: DragZoom = new DragZoom({ condition: always, out: true });

export var ActiveMapMode: MapMode = MapMode.Identify;


//Layer Popper
const layerPopper = AddPopper("Layers", layersPanel, btnLayers, 'bottom');
const searchPopper = AddPopper("Search", searchPanel, btnSearch, 'bottom');
const trafficPopper = AddPopper("Traffic", trafficPanel, btnTraffic, 'bottom');

export function Toast(msg: string) {
	(<any>window).showToast(msg);
}


export function ActivateToolButton(btn: HTMLButtonElement) {
	if (btn == null) return;
	btn.classList.remove("btn-light");
	btn.classList.add("btn-primary");
}

export function DeactivateToolButton(btn: HTMLButtonElement) {
	if (btn == null) return;
	btn.classList.add("btn-light");
	btn.classList.remove("btn-primary");
}

export function SetMapMode(mode: MapMode) {
	DeactivateToolButton(btnZoomIn);
	DeactivateToolButton(btnZoomOut);
	DeactivateToolButton(btnIdentify);
	DeactivateToolButton(btnIdentify);
	DeactivateToolButton(btnMeasureArea);
	DeactivateToolButton(btnMeasureLength);

	map.removeInteraction(zoomInInteraction);
	map.removeInteraction(zoomOutInteraction);

	SetMeasureMode(mode);
	if (mode == MapMode.ZoomIn) {
		map.addInteraction(zoomInInteraction);
		ActivateToolButton(btnZoomIn);
		mapDiv.style.cursor = 'zoom-in';
	}
	else if (mode == MapMode.ZoomOut) {
		map.addInteraction(zoomOutInteraction);
		ActivateToolButton(btnZoomOut);
		mapDiv.style.cursor = 'zoom-out';
	}
	else if (mode == MapMode.Identify) {
		ActivateToolButton(btnIdentify);
		mapDiv.style.cursor = 'auto';
	}
	else if (mode == MapMode.MeasureArea) {
		ActivateToolButton(btnMeasureArea);
		mapDiv.style.cursor = 'auto';
	}
	else if (mode == MapMode.MeasureLength) {
		ActivateToolButton(btnMeasureLength);
		mapDiv.style.cursor = 'auto';
	}

	ActiveMapMode = mode;
}

function UpdateLayerPanelHeight() {
	let docHeight = window.innerHeight;
	layerTreeContainer.style.maxHeight = (docHeight - 270).toString() + "px";
	layersPanel.style.width = "22rem";
}

//Control Events
btnZoomIn.onclick = function () {
	if (ActiveMapMode == MapMode.ZoomIn)
		SetMapMode(MapMode.Identify);
	else
		SetMapMode(MapMode.ZoomIn);
}

btnZoomOut.onclick = function () {
	if (ActiveMapMode == MapMode.ZoomOut)
		SetMapMode(MapMode.Identify);
	else
		SetMapMode(MapMode.ZoomOut);
}

btnIdentify.onclick = function () {
	SetMapMode(MapMode.Identify);
}

btnTraffic.onclick = function () {
	trafficPopper.togglePopper();
}

btnLayers.onclick = function () {
	UpdateLayerPanelHeight();
	layerPopper.togglePopper();
}

btnSearch.onclick = function () {
	txtSearch.value = "";
	searchPopper.togglePopper();
}

btnApplySearch.onclick = function(){
	let query = txtSearch.value;
	$.ajax({
		type: "GET",
		url: "/AutoComplete/GetPosition",
		data: {query: query},
		success: function (resp) {
			map.getView().setCenter(resp)
			if(map.getView().getZoom() < 19){
				map.getView().setZoom(19);
			}
		}
	})
	
}

btnMeasureLength.onclick = function () {
	if (ActiveMapMode == MapMode.MeasureLength)
		SetMapMode(MapMode.Identify);
	else
		SetMapMode(MapMode.MeasureLength);
}

btnMeasureArea.onclick = function () {
	if (ActiveMapMode == MapMode.MeasureArea)
		SetMapMode(MapMode.Identify);
	else
		SetMapMode(MapMode.MeasureArea);
}

function SetupIdentifyOverlay() {
	var overlay = new Overlay({
		element: mapPopup
	});
	map.addOverlay(overlay);

	map.on('singleclick', function (evt) {
		if (ActiveMapMode != MapMode.Identify) return;
		if (map.hasFeatureAtPixel(evt.pixel) == false) return;


		let coordinate = evt.coordinate;
		let lonLat = toLonLat(coordinate);

		let feature = map.getFeaturesAtPixel(evt.pixel) as Feature<any>[];
		if (feature.length == 1) {
			overlay.setPosition(coordinate);
			
			let f = feature[0];
			let props = f.getProperties();
			let layerName = props["_layer"];
			if (layerName == "Tracker") layerName = props["Vehicle Type"];

			let content = `<h5>${layerName}</h5><table class='table table-condensed table-bordered'><thead><tr><th>Field</th><th>Value</th></tr></thead><tbody>`

			let keys = Object.keys(props);
			console.log(keys)
			for(let key of keys){
				if(key.startsWith("_"))continue;
				if(key == "geometry")continue;
				content+= "<tr><td>" + key + "</td><td>" + props[key] + "</td></tr>"
			}

			content += "</tbody></table>"
			
			let login = document.querySelector<HTMLInputElement>('#loggedIn')
			if(keys.indexOf("_id") >= 0 &&  login.value == "true" && props["_layer"] == "Tracker") {
				content += `<a class='btn btn-primary' href='Tracker/Edit?id=${props["_id"]}'>Edit</a>`
			}
			mapPopupContent.innerHTML = content;
			overlay.panIntoView({ animation: { duration: 500 }, margin: 80 });

			
			console.log(props);
		}
	});

	mapPopupCloser.onclick = function () {
		overlay.setPosition(undefined);
		mapPopupCloser.blur();
		SetMapMode(MapMode.Identify);
		return false;
	};
}


window.onresize = function () {
	UpdateLayerPanelHeight();
}


export function onIdentifyFeatureChange() {
	let selIdentifyFeature = document.querySelector<HTMLSelectElement>("#selIdentifyFeature");
	let option = selIdentifyFeature.item(selIdentifyFeature.selectedIndex);
	let fid = option.getAttribute('data-id');
	let table = option.getAttribute('data-table');

	let tables = document.querySelectorAll<HTMLDivElement>(".identify-table");
	tables.forEach(tbl => {
		let fid1 = tbl.getAttribute('data-id');
		let table1 = tbl.getAttribute('data-table');
		tbl.hidden = !(fid === fid1 && table == table1);
	});

}




//The main map object

export const map: olMap = new olMap({
	target: mapDiv,
	controls: [new Attribution({ collapsed: false })],
	interactions: defaults({ altShiftDragRotate: false, pinchRotate: false, }),
	maxTilesLoading: 256
});

map.addControl(new Rotate())

map.getView().setMinZoom(4);
map.getView().setConstrainResolution(true);

function Start() {
	let userId = $("#userId").val() as number;
	let extents = $("#extents").val() as string;
	let bgMap = $("#bgMap").val() as number;
	let filter = $("#filter").val() as string;

	InitMeasureTool(map);
	InitMapLayers(map);

	LoadMapExtents(userId, map, extents);

	SetMapMode(ActiveMapMode);
	SetupIdentifyOverlay();

	SetupBaseMaps(map)
	SetActiveBasemap(GetBgMapState(userId, bgMap));


	map.on('pointermove', evt => {
		if (ActiveMapMode == MapMode.Identify) {

			if (map.hasFeatureAtPixel(evt.pixel)) {
				mapDiv.style.cursor = 'help';
			}
			else if (!evt.dragging) {
				mapDiv.style.cursor = 'auto';
			}
			else {
				mapDiv.style.cursor = 'grabbing';
			}
		}

		UpdateMeasureTool(evt);
	});

	map.on('precompose', evt => {
		$('#loadingMap').css('visibility', 'visible');
	});

	map.on('rendercomplete', evt => {
		$('#loadingMap').css('visibility', 'hidden');
	});

	AddTrackerLayers();
}

Start();
cover.hidden = true;
