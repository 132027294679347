import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import { Map as olMap } from 'ol';
import { SaveMapState } from "./MapViewSession";


//Background map layers
const bingMapsKey = 'AgQWbbGHgYWfZh-5TNuxtRW-dze6zicUs1EfgsDbhePIKekLgMNKPum1TDGGXdAr';
export const MapboxKey = 'pk.eyJ1IjoiYXZpbmFibWFsbGEiLCJhIjoiY2w0c3Q4cHBlMDdhcTNqbzY0eHk3ZGRwaCJ9.gVYWlLJD-sWfJ3qkS_dzOg';

const osmlayer = new TileLayer({ source: new OSM() });
const googleSatelliteLayer = new TileLayer({ zIndex: 0, source: new XYZ({ url: 'https://mt{0-3}.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}', maxZoom: 21 }) });
const googleTerrainLayer = new TileLayer({ zIndex: 0, source: new XYZ({ url: 'https://mt{0-3}.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}', maxZoom: 21 }) });
const googleMapLayer = new TileLayer({ zIndex: 0, source: new XYZ({ url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', maxZoom: 21 }) });
const googleHybridLayer = new TileLayer({ zIndex: 0, source: new XYZ({ url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', maxZoom: 21 }) });



const bingRoadLayer = new TileLayer({ zIndex: 0, source: new BingMaps({ key: bingMapsKey, imagerySet: 'Road', maxZoom: 19, interpolate: true }) });
const bingAerialLayer = new TileLayer({ zIndex: 0, source: new BingMaps({ key: bingMapsKey, imagerySet: 'Aerial', maxZoom: 19, interpolate: true }) });
/*const MapboxSatelliteLayer = new TileLayer({
	zIndex: 0,
	source: new XYZ({
		url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=" + MapboxKey,
		maxZoom: 21,
		attributions: `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
		attributionsCollapsible: false,
		interpolate: true
	})
});

const MapboxDarkLayer = new TileLayer({
	zIndex: 0,
	source: new XYZ({
		url: "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/256/{z}/{x}/{y}?access_token=" + MapboxKey,
		maxZoom: 21,
		attributions: `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
		attributionsCollapsible: false,
		interpolate: true
	}),
});


const MapboxLightLayer = new TileLayer({
	zIndex: 0,
	source: new XYZ({
		url: "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=" + MapboxKey,
		maxZoom: 21,
		attributions: `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
		attributionsCollapsible: false,
		interpolate: true
	}),
});


const MapboxStreetsLayer = new TileLayer({
	zIndex: 0,
	source: new XYZ({
		url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=" + MapboxKey,
		maxZoom: 21,
		attributions: `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
		attributionsCollapsible: false,
		interpolate: true
	}),
});*/

let mapView: olMap = null;
export var CurrentBaseMap: BaseMapLayer = null;
export class BaseMapLayer {
	layer: TileLayer<any>;
	name: string;
	index: number;

	constructor(index: number, name: string, layer: TileLayer<any>) {
		this.index = index;
		this.name = name;
		layer.setOpacity(0.65);
		this.layer = layer;
	}
}

export var BaseMaps: BaseMapLayer[] = [];

const selBgMap = document.querySelector<HTMLSelectElement>('#selBgMap');

selBgMap.onchange = function () {
	let option = selBgMap.item(selBgMap.selectedIndex).innerHTML;
	BaseMaps.forEach(bm => {
		mapView.removeLayer(bm.layer);
	});

	BaseMaps.forEach(bm => {
		if (option === bm.name) {
			mapView.addLayer(bm.layer);
			bm.layer.setZIndex(1);
			CurrentBaseMap = bm;
		}
	});

	SaveMapState( mapView, CurrentBaseMap.index);
}

export function SetupBaseMaps(map: olMap) {

	BaseMaps.push(new BaseMapLayer(1, "OpenStreetMap", osmlayer));
	BaseMaps.push(new BaseMapLayer(2, "Google Maps", googleMapLayer));
	BaseMaps.push(new BaseMapLayer(3, "Google Satellite", googleSatelliteLayer));
	BaseMaps.push(new BaseMapLayer(4, "Google Hybrid", googleHybridLayer));
	BaseMaps.push(new BaseMapLayer(5, "Google Terrain", googleTerrainLayer));
	BaseMaps.push(new BaseMapLayer(6, "Bing Road", bingRoadLayer));
	BaseMaps.push(new BaseMapLayer(7, "Bing Aerial", bingAerialLayer));
	//BaseMaps.push(new BaseMapLayer(8, "Mapbox Satellite", MapboxSatelliteLayer));
	//BaseMaps.push(new BaseMapLayer(9, "Mapbox Streets", MapboxStreetsLayer));
	//BaseMaps.push(new BaseMapLayer(10, "Mapbox Light", MapboxLightLayer));
	//BaseMaps.push(new BaseMapLayer(11, "Mapbox Dark", MapboxDarkLayer));

	mapView = map;
	let noneOption = document.createElement('option');
	noneOption.value = "0";
	noneOption.textContent = "None"

	selBgMap.options.add(noneOption);

	BaseMaps.forEach(bm => {
		let mapOption = document.createElement('option');
		mapOption.value = bm.index.toString();
		mapOption.textContent = bm.name;

		selBgMap.options.add(mapOption);
	});

}

export function SetActiveBasemap(bgMap: number) {
	BaseMaps.forEach(bm => {
		mapView.removeLayer(bm.layer);
	});

	BaseMaps.forEach(bm => {
		if (bgMap == bm.index) {
			mapView.addLayer(bm.layer);
			CurrentBaseMap = bm;
		}
	});

	let options = selBgMap.options;
	for (let i = 0; i < options.length; i++) {
		if (parseInt(options[i].value) == bgMap) {
			selBgMap.selectedIndex = i;
		}
	}

}